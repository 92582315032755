import { AfterContentInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

@Component({
    template: `
    <a *ngIf="isVisible" [routerLink]="targetLink" routerLinkActive="active">
      <i class="fa fa-exclamation-triangle text-yellow" data-toggle="tooltip"
         [title]="'store.detail.fields.inconsistencyTooltip' | translate"></i>
    </a>
  `,
    styles: ['a {cursor: pointer}'],
    standalone: false
})
export class CheckLinkRenderComponent implements AfterContentInit {

  targetLink: string | undefined;
  isVisible: boolean = true;

  @Input() value: any;
  @Input() rowData: any;

  @Output() changesEmitter: EventEmitter<any> = new EventEmitter();

  ngAfterContentInit() {
    this.changesEmitter.emit();
  }

  protected readonly faExclamationTriangle = faExclamationTriangle;
}
